<template>
  <div>
 

    <div class="text-right" v-if="manageService('can_add')">
    <div v-if="progressInfos">
      <div class="mb-2"
        v-for="(progressInfo, index) in progressInfos"
        :key="index"
      >
        <span>{{progressInfo.fileName}}</span>
        <div class="progress">
          <div class="progress-bar progress-bar-info"
            role="progressbar"
            :aria-valuenow="progressInfo.percentage"
            aria-valuemin="0"
            aria-valuemax="100"
            :style="{ width: progressInfo.percentage + '%' }"
          >
            {{progressInfo.percentage}}%
          </div>
        </div>
      </div>
    </div>

    <label class="btn btn-primary">
      <input type="file" multiple @change="selectFile($event)"  />
    </label>

    <button class="btn btn-success"
      :disabled="!selectedFiles"
      @click="uploadFiles"
    >
      رفع الملفات
    </button>

    <div v-if="message" class="alert alert-light" role="alert">
      <ul>
        <li v-for="(ms, i) in message.split('\n')" :key="i">
          {{ ms }}
        </li>
      </ul>
    </div>
    </div>
 
  </div>
</template>

<script>
import UploadService from "../services/UploadFilesService";
import store from '../store/index'
 import {mapState,mapMutations} from 'vuex';
 


 
export default {
  name: "upload-files",
  props:['uuid'],

     computed:{
    ...mapState({
 
    }),
    manageService: (state) => (service) => {
            return store.getters['admin/canManageService'](service);
    },
   }, 
 
   components:{
  
   },
  data() {
    return {
      selectedFiles: undefined,
      progress: 0,
      message: "",
      NumberUploadedFiles:0,
      NumberSelectedFiles:0,

      progressInfos: [],

      AlertTitle:'',
      AlertMessage:'',

      SelectedFileForDelete:'',
      sureResult:true,

 

    };
  },
  methods: {
 
    selectFile(event) {
      this.progressInfos = [];
      this.selectedFiles = event.target.files;
      this.NumberSelectedFiles= this.selectedFiles.length;
    },
    uploadFiles() {
      this.message = "";

      for (let i = 0; i < this.selectedFiles.length; i++) {
        this.upload(i, this.selectedFiles[i]);
      }
    },

    upload(idx, file) {
      this.progressInfos[idx] = { percentage: 0, fileName: file.name };

      UploadService.upload(file, (event) => {
        this.progressInfos[idx].percentage = Math.round(100 * event.loaded / event.total);
      },this.uuid)
        .then((response) => {
          let prevMessage = this.message ? this.message + "\n" : "";
          this.message = prevMessage + response.data.message;
         // alert("DONE");
          return this.$emit("uploaded");
        })
        .then((files) => {
        //  this.fileInfos = files.data;
            this.NumberUploadedFiles++;
            if(this.NumberSelectedFiles == this.NumberUploadedFiles){
 

              this.progressInfos=[],
              this.selectedFiles=undefined;
              this.message='';
              this.NumberSelectedFiles=0;
              this.NumberUploadedFiles=0;

            }
            

        })
        .catch(() => {
          this.progressInfos[idx].percentage = 0;
          this.message = "حدث مشكلة اثناء رفع الملف:" + file.name;
        });
    },
 
  },


};
</script>
<style scoped>
.btn-success:disabled{
    color: #fff;
    background-color: #d2d2d2;
    border-color: #343a40;
    box-shadow: none;
    }
.btn-success:disabled:hover{
    color: #fff;
    background-color: #d2d2d2;
    border-color: #343a40;
    box-shadow: none;
    }    
   .title{
     max-width: 200px !important;
   } 
   .status{
     max-width: 150px !important;
   } 
   .watch{
     max-width: 150px !important;
   } 
   .created_at{
     max-width: 150px !important;
   } 
   .inserted_by{
     max-width: 150px !important;
   } 
   .operations{
     max-width: 180px !important;
   } 
</style>