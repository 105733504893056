<template>

  <div class="container-fluid">
      <ConfirmAlert ref="MyConfirmAlert" :title="AlertTitle" :message="AlertMessage" @Sure="YesIamSure($event)" :sureResult="sureResult" />
         <SuccessAlert ref="MySuccessAlert" :title="AlertTitle" :message="AlertMessage" :back="false"/>

            <div class="col-md-12">
                <upload-files @uploaded="onPaginationChage(1)" :uuid="filter.uuid" ></upload-files>
            </div>

            <div class="col-md-12">
              <div class="card">
                <div class="card-header card-header-primary" style="text-align: center;">
                  <h4 class="card-title "> قائمة المرفقات الخاصة بالمنتج ( {{Product.name['ar']}} )</h4>
                </div>
                <div class="card-body" style="text-align:center;">
                  <div class="table-responsive">
                    <table class="table">
                      <thead class=" text-primary">
                        <th>
                          #
                        </th>
                        <th>
                          اسم المنتج
                        </th>
                        <th>
                          الصورة
                        </th>
                        <th>
                          رئيسية
                        </th>
                        <th>
                          ترتيب
                        </th>
                        
                        <th v-if="manageService('can_edit') || manageService('can_delete')">
                          اجراءات
                        </th>

                      </thead>
                      <tbody>
                        <tr v-for="(attachment,index) in AttachmentsList.data" :key="index">
                          <td>
                            {{index+1}}
                          </td>
                          <td>
                            {{attachment.product.name.ar}}
                          </td>
                          <td>
                            <img width="175" height="175" :src="'https://pode.ly'+attachment.img_path"/>
                          </td>
                          <td>
                            {{attachment.is_main?'نعم':'لا'}}
                          </td>
                          <td>
                            {{attachment.sort}}
                          </td>

                          <td class="text-primary" v-if="manageService('can_edit') || manageService('can_delete')">
                            <router-link v-if="manageService('can_edit')" :to="{name: 'admin.dashboard.product.attachment.update', params: {id: attachment.id}}" class="btn btn-warning btn-sm" > 
                             تعديل</router-link>
                             <a href="" v-if="manageService('can_delete')" class="btn btn-danger btn-sm" @click.prevent="ShowConfirm(attachment)">
                             حذف</a> 
                          </td>
                        </tr>
                       </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12">
             <pagination v-if="AttachmentsList.meta.last_page > 1" :pagination="AttachmentsList" :offset="5" @paginate="onPaginationChage"></pagination> 
             </div>
</div>
</template>

<script>
import UploadFiles from "../../../../components/UploadFiles";
import {mapState,mapMutations} from 'vuex';
import store from '../../../../store/index'

 
import Pagination from '../../../../components/Pagination.vue'
import PerPage from '../../../../components/PerPage'
import ConfirmAlert from '../../../../components/ConfirmAlert'
import SuccessAlert from '../../../../components/SuccessAlert'
export default {
  name: "App",
  data(){
      return{
        filter:{
            search:'',
            page:1,
            paginate:1,
            limit:999,
            uuid:'',
        }, 
        AlertTitle:'',
        AlertMessage:'',
        SelectedAttachment:null,
        sureResult:true,
      }
  },
   computed:{
    ...mapState({
        AttachmentsList:state=>state.admin.attachments.ProductAttachmentsList,
        Product:state=>state.admin.products.Product.data,
    }),
    manageService: (state) => (service) => {
            return store.getters['admin/canManageService'](service);
    },
    
   },  
   methods:{
       onPaginationChage(page){
         store.commit('admin/PleaseStartLoading');
          this.filter.page=page;
         store.dispatch('admin/fetchProductAttachmentsList',this.filter).then((response)=>{
           store.commit('admin/PleaseStopLoading');
         }).catch((error)=>{
           store.commit('admin/PleaseStopLoading');
         })
       },
       YesIamSure(value){
        if(value && this.sureResult){
            this.sureResult=false;
            this.onDelete();
        }
        },
        CancelAlert(){
        this.sureResult=false;
        },
        ShowConfirm(attachment){
                this.AlertTitle="معلومات",
                this.AlertMessage="هل انت متاكد من الحذف ؟";
                this.$refs.MyConfirmAlert.showModel();
                this.SelectedAttachment=attachment;
        },
     onDelete(){
           store.commit('admin/PleaseStartLoading');
          store.dispatch('admin/deleteAttachment',this.SelectedAttachment.id).then((Response)=>{
            store.commit('admin/PleaseStopLoading');
            this.sure=false;
           this.$refs.MyConfirmAlert.hideModel();
            this.AlertTitle="معلومات",
            this.AlertMessage="تمت الحذف بنجاح";
            this.$refs.MySuccessAlert.showModel();
            store.dispatch('admin/fetchProductAttachmentsList',this.filter).then(()=>{
             this.sureResult=true;
            }).catch(error=>{

            })
          }).catch((error)=>{
         store.commit('admin/PleaseStopLoading');
        });
        

    },
   },
  components: {
    UploadFiles,
    Pagination,
    PerPage,
    ConfirmAlert,
    SuccessAlert

  },
         beforeRouteEnter(to,from,next){    
            let uuid=to.params.uuid; 
            store.commit('admin/PleaseStartLoading');
            store.dispatch('admin/fetchProductAttachmentsList',{
                page:1,
                paginate:1,
                uuid:uuid,limit:999,}).then((response) => {
               store.dispatch('admin/fetchProduct', uuid).then((response) => {   
            store.commit('admin/PleaseStopLoading');
                next(vm => {
                    vm.filter.uuid=uuid;
                });
                }).catch(()=>{
            store.commit('admin/PleaseStopLoading');
            })
            }).catch(()=>{
            store.commit('admin/PleaseStopLoading');
            })

    },
};
</script>