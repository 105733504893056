import store from '../store/index'
import http from '../utils/axios/admin';

class UploadFilesService {
    upload(file, onUploadProgress, uuid) {
        let formData = new FormData();

        formData.append("file", file);

        return http.post("https://pode.ly/api/admin/upload-products-attachments/" + uuid, formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            },
            onUploadProgress
        });
    }


}

export default new UploadFilesService();